import { PlacementProps } from ".";
import { Ad } from "../../../atoms/ad";

const Skyscraper = ({ ...rest }: PlacementProps) => {
  return (
    <Ad
      {...rest}
      sizes={[120, 600]}
      width={120}
      height={600}
      targetingArguments={{ pos: "skyscraper" }}
      sticky
      desktop
      hideOnTablet
    />
  );
};

export { Skyscraper };
