import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://110709bec539cf73e875b572a4bc4a61@o4504326775701504.ingest.us.sentry.io/4508330538958848",
  debug: false,
  environment: "production",
  release: "dpl_HDX3DB5wUY953ZTGugxFGurxkbFu",
  tracesSampleRate: 1,
  sampleRate: 0.1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.8,
});