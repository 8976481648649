import { PlacementProps } from ".";
import { cn } from "../../../../utils";
import { Ad } from "../../../atoms/ad";

const Billboard = ({
  mobileOffset,
  desktopOffset,
  mobileClassName,
  desktopClassName,
  offset,
  className,
  ...rest
}: PlacementProps) => {
  return (
    <>
      <Ad
        {...rest}
        offset={desktopOffset || offset}
        sizes={[
          [970, 250],
          [970, 90],
          [728, 90],
          [728, 200],
        ]}
        height={250}
        targetingArguments={{ pos: "billboard" }}
        className={cn(className, desktopClassName)}
        desktop
      />

      <Ad
        {...rest}
        offset={mobileOffset || offset}
        sizes={[
          [336, 280],
          [300, 250],
          [300, 50],
          [320, 50],
          [300, 100],
          [320, 100],
        ]}
        height={280}
        targetingArguments={{ pos: "billboard" }}
        className={cn(className, mobileClassName)}
        mobile
      />
    </>
  );
};

export { Billboard };
