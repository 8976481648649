import { Icon } from "../atoms/icon/icon";
import { cn, sanitizeFilter } from "../../utils";
import type { SliceRule } from "api/slices/types";
import { Input } from "../atoms/input";
import { useState } from "react";
import { ListingFilterItemCheckbox } from "./listing-filter-item-checkbox";
import { buttonVariants } from "../atoms/button";

interface ListingFilterItemProps {
  name: string;
  rules?: SliceRule[];
  onValueChange?: (name: string, value: string) => void;
  onReset?: (name: string) => void;
  onCheckChange?: (name: string, rule: SliceRule, checked: boolean) => void;
  filters: { id?: number; name: string }[];
  defaultOpen?: boolean;
  className?: string;
}

const ListingFilterItem = ({
  name,
  rules,
  onCheckChange,
  onReset,
  onValueChange,
  filters,
  defaultOpen,
  className,
}: ListingFilterItemProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [showAll, setShowAll] = useState(false);

  const getItem = () => {
    const isChecked = (rule: SliceRule) =>
      filters.some((filter) => {
        return sanitizeFilter(filter.name) === sanitizeFilter(rule.name);
      });

    const groupChecked = rules ? rules.some((rule) => isChecked(rule)) : false;

    const filteredRules = rules?.filter((rule) =>
      !groupChecked ? rule.amount > 0 : true
    );

    const getSpliceLength = (total: number) => {
      if (!showAll) return 5;

      return total;
    };

    switch (name) {
      case "Prijs (in Euro)":
        if (onValueChange === undefined) return;

        return (
          <div className="flex items-center gap-2">
            <Input
              value={sanitizeFilter(filters[0].name)}
              type="number"
              onChange={(e) => onValueChange("minPrice", e.target.value)}
              placeholder="van"
              className="h-10 text-xs"
            />
            tot
            <Input
              value={sanitizeFilter(filters[1].name)}
              type="number"
              onChange={(e) => onValueChange("maxPrice", e.target.value)}
              placeholder="tot"
              className="h-10 text-xs"
            />
          </div>
        );
      case "Verfijn resultaten":
        if (onValueChange === undefined) return;

        return (
          <Input
            value={sanitizeFilter(filters[0].name)}
            onChange={(e) => onValueChange("searchQuery", e.target.value)}
            placeholder="trefwoord"
            className="h-10 text-xs"
          />
        );
      case "Merken":
        if (onCheckChange === undefined) return;

        return (
          <>
            <li>
              <Input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Zoek op merken"
                className="h-10 text-xs"
              />
            </li>

            {filteredRules
              ?.filter((r) =>
                r.name.toLowerCase().includes(searchValue.toLowerCase())
              )
              .splice(0, getSpliceLength(filteredRules.length))
              .map((rule, index) => (
                <ListingFilterItemCheckbox
                  key={`${rule.name}_${index}`}
                  rule={rule}
                  filterName={name}
                  onChange={onCheckChange}
                  isChecked={isChecked(rule)}
                  groupChecked={filters.length > 0}
                />
              ))}
          </>
        );
      default:
        if (onCheckChange === undefined) return;

        return filteredRules
          ?.splice(0, getSpliceLength(filteredRules.length))
          .map((rule, index) => {
            return (
              <ListingFilterItemCheckbox
                key={`${rule.name}_${index}`}
                rule={rule}
                filterName={name}
                onChange={onCheckChange}
                isChecked={isChecked(rule)}
                groupChecked={groupChecked}
              />
            );
          });
    }
  };

  if (rules && rules.filter((rule) => rule.amount > 0).length === 0) return;

  return (
    <details
      open={defaultOpen}
      className={cn("text-link group open:text-heading", className)}
    >
      <summary
        className={cn(
          "relative cursor-pointer border-t h-[60px] flex items-center px-5 justify-between",
          "lg:h-auto lg:px-2.5 lg:pt-2.5 lg:mt-2.5 lg:font-semibold lg:text-heading lg:list-item",
          "marker:text-[10px]"
        )}
      >
        {name}

        <Icon
          icon="chevronBoldDown"
          size={12}
          className="lg:hidden group-open:rotate-180"
        />

        {onReset !== undefined && Object.values(filters).length > 0 && (
          <button
            type="button"
            className="hidden lg:block text-link font-normal absolute right-2.5 top-0 h-10 text-xs"
            onClick={() => onReset(name)}
          >
            Wis
          </button>
        )}
      </summary>

      <ul className="mt-1 px-2.5 flex flex-col gap-1 group-open:bg-muted pt-2.5 pb-1.5">
        {getItem()}{" "}
        <li>
          <button
            type="button"
            className={cn(
              buttonVariants({ variant: "link" }),
              "text-xs justify-start w-fit flex py-2.5 px-0 lg:py-0 h-fit",
              { hidden: !rules || rules.length <= 5 }
            )}
            onClick={() => setShowAll((prev) => !prev)}
          >
            <Icon icon={showAll ? "minus" : "plus"} size={8} />
            <span>Toon {showAll ? "minder" : "meer"}</span>
          </button>
        </li>
      </ul>
    </details>
  );
};

export { ListingFilterItem };
